<template>
  <Pagination v-bind="$attrs" v-on="$listeners" :itemsCount="quizCount" :greenItems="correctAnswered" :redItems="wrongAnswered" :yellowItems="notAnswered" />
</template>

<script>
import Pagination from '@/components/ui/Pagination.vue';

export default {
  name: 'QuizPagination',
  inheritAttrs: false,
  components: { Pagination },

  props: {
    quizArray: { type: Array, required: true },
    graded: { type: Boolean, default: false },
  },

  data() {
    return {
      quizCount: this.quizArray.length,
      correctAnswered: [],
      wrongAnswered: [],
      notAnswered: [],
    };
  },

  methods: {
    sortQuizzes() {
      if (this.graded && this.quizCount) {
        this.correctAnswered = [];
        this.wrongAnswered = [];
        this.notAnswered = [];
        this.quizArray.forEach((quiz, idx) => {
          const quizNo = idx + 1; // 1 is added because pagination works with absolute positions, not indices.
          if (quiz.correct_answer === true) this.correctAnswered.push(quizNo);
          else if (quiz.user_answers && quiz.user_answers.length) this.wrongAnswered.push(quizNo);
          else this.notAnswered.push(quizNo);
        });
      }
    },
  },

  mounted() {
    this.sortQuizzes();
  },
};
</script>
